// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "summary", "details", "quantity" ]

  initialize() {
    this.orders = {
      line_items: [],
      subtotal: 0.0,
      service_charge: 0.0,
      delivery_charge: 3.0,
      grand_total: 0.0,
      order_type: 'delivery'
    }
    this.detailsTarget.innerHTML = '<i class="fas fa-cart-arrow-down empty-cart"></i>';
  }

  connect() {
  }

  add_to_cart(e) {
    this.detailsTarget.querySelector('.empty-cart').style.display = "none"
    let params = {title: 'Dosa', id: 23, price: 23}
    let lineItem = this.renderItem(params);
    this.orders.line_items.push(params);
    console.log(this.orders.line_items);
    this.detailsTarget.innerHTML += lineItem;
  }

  addQuantity(e) {
    let quantity = e.target.parentElement.querySelector('input');
    quantity.value = parseInt(quantity.value) + 1;
  }

  reduceQuantity(e) {
    let quantity = e.target.parentElement.querySelector('input');
    if (parseInt(quantity.value) > 1) {
      quantity.value = parseInt(quantity.value) - 1;
    } else {
      e.target.parentElement.parentElement.remove();
    }
  }

  renderItem(item) {
    if (item) {
      return `
        <div data-id="${item.id}" class="line-item">
          <div class="cart-item-quantity">
            <button class="btn btn-sm btn-default" data-action="click->cart#reduceQuantity">-</button>
            <input type="text" value="0"  data-target="cart.quantity"/>
            <button class="btn btn-sm btn-default" data-action="click->cart#addQuantity">+</button>
          </div>
          <div class="cart-item-title">
            ${item.title}
          </div>
          <div class="cart-item-title currency">
            ${item.price}
          </div>
        </div>
      `;
    } else { return ''; }
  }
}
