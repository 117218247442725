// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
// require("@turbolinks").start()
//= require turbolinks
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import $ from 'jquery';
global.$ = jQuery;
import "bootstrap"
import "controllers"


$(document).ready(function(){
    if (localStorage.getItem("location") )
        $('#delivery-location').html(JSON.parse(localStorage.getItem("location"))["name"])
    else{
        $('#location-modal').modal({
            backdrop: 'static',
            keyboard: false
        })
    }
    $("#delivery-location-main").click(function() {
        // $('#store_location').val(JSON.parse(localStorage.getItem("location"))["name"])
        $('#location-modal').modal({
            backdrop: 'static',
            keyboard: false
        })
    })
    $('#location-submit').click(function() {
        var lat = $("#store_latitude").val()
        var lng = $("#store_longitude").val()
        var loc_name = $("#store_location").val()
        if(lat == "" || lng == ""){
            return  false
        }else{
            localStorage.setItem("location", JSON.stringify({"latitude": lat, "longitude": lng, "name": loc_name}));
            $('#location-modal').modal('hide');
            $('#delivery-location').text(loc_name)
            sortStore();
            $('html, body').animate({
                'scrollTop' : $("#restaurants").position().top
            });
        }
    })
    updateCartIcon()
  // Add minus icon for collapse element which is open by default
  $(".collapse.show").each(function(){
      $(this).prev(".card-header").find(".fas").addClass("fa-angle-up").removeClass("fa-angle-down");
  });
  
  // Toggle plus minus icon on show hide of collapse element
  $(".collapse").on('show.bs.collapse', function(){
      $(this).prev(".card-header").find(".fas").removeClass("fa-angle-down").addClass("fa-angle-up");
  }).on('hide.bs.collapse', function(){
      $(this).prev(".card-header").find(".fas").removeClass("fa-angle-up").addClass("fa-angle-down");
  });

    // $('body').on('click', function(e){
    //   var buttonClicked = event.target
    //   buttonClicked.parentElement.parentElement.remove()
    //   updateCartTotal()
    // })

    $('input[type=radio][name=order_type]').change(function() {
        if (this.value == 'take-away'){
            $('#address-fields').text('Vehicle Details:')
            $('#landmark-field').hide();
        }
        else {
            $('#address-fields').text('Full Address:')
            $('#landmark-field').show();
        }
    });
    $('body').on('click', '.btn-add', function(e){
      let quantity = e.target.parentElement.querySelector('input');
      quantity.value = parseInt(quantity.value) + 1;
      updateCartTotal()
    });

    $('body').on('click', '.btn-reduce', function(e){
      let quantity = e.target.parentElement.querySelector('input');
      if (parseInt(quantity.value) > 1) {
        quantity.value = parseInt(quantity.value) - 1;
      } else {
        e.target.parentElement.parentElement.remove();
        updateCartIcon()
      }
      // if($('.line-item').length == 0) $('.btn-purchase').prop( "disabled", true );
      updateCartTotal()
    });

    $('body').on('click', '.shop-item-button', function(e) {
      var shopItem = this.parentElement
      document.querySelector('.empty-cart').style.display = 'none';
      // $('.btn-purchase').prop( "disabled", false );
      var title = shopItem.getElementsByClassName('title')[0].innerText
      var price = shopItem.getElementsByClassName('price')[0].innerText
      var itemSlug = $(this).attr('data-slug');
      var itemId = $(shopItem).find('.shop-item-button').attr('data-id');
      var imageSrc = '' //shopItem.getElementsByClassName('shop-item-image')[0].src
      addItemToCart(title, price, imageSrc, itemSlug, itemId);
      updateCartTotal()
    })

    // document.getElementsByClassName('btn-purchase')[0].addEventListener('click', purchaseClicked)

    $('#submit-order').click(function() {
      $('.form-control').removeClass('is-invalid')
      var name = $('#customer-name').val()
      var phone = $('#customer-phone').val()
      var address = $('#customer-full-address').val()
      var landmark = $('#customer-landmark').val()
      var cart_items = $('.cart-details').find('.line-item')
      var order_type = $('form input[type=radio]:checked').val()
      if(name.trim() == "" || phone.trim() == "" || cart_items.length == 0 ){
          if(name.trim() == "") $('#customer-name').addClass('is-invalid')
          if(phone.trim() == "") $('#customer-phone').addClass('is-invalid')
          if(address.trim() == "") $('#customer-full-address').addClass('is-invalid')
          return false
      }
      if(phone.trim().length < 8){
          $('#customer-phone').addClass('is-invalid')
          return false
      }
      var loc = JSON.parse(localStorage.getItem("location"))
      var online_order = {
      "delivery_address": {"name": name, "phone": phone, "address": address, "landmark": landmark, "latitude": loc["latitude"], "longitude": loc["longitude"]}
      }
      var line_items = []
      for (var i = 0; i < cart_items.length; i++) {
        var cart_input = $(cart_items[i]).find('.cart-quantity-input')
        var total_price = parseInt(cart_input.val()) * parseFloat($(cart_items[i]).find('.cart-item-price').text().trim())
        line_items.push({"product_id": cart_input.data('id'), "quantity": cart_input.val(), "price": cart_input.data('price'), "total_price": total_price})
      }
      $.ajax({
        url: '/api/v1/orders.json',
        type: 'POST',
        data: {order: {
            "grand_total": $('.cart-total-price').html(),
            "service_charge": 0,
            "order_type": order_type,
            "store_id": $('#store-id').val(),
            online_order_attributes: online_order,
            line_items_attributes: line_items
          }},
        success: function(data){
            $('.order-id').text(data['order_id'])
            $('#addressDetails').modal('hide');
            $('#order-success').modal({
                backdrop: 'static',
                keyboard: false
            })
        },error:function(data){
          console.log(data.errors)
        }
      })
    })
    sortStore();
});

function purchaseClicked() {
  alert('Thank you for your purchase');
  var cartItems = document.getElementsByClassName('cart-details')[0]
  while (cartItems.hasChildNodes()) {
      cartItems.removeChild(cartItems.firstChild)
  }
  updateCartTotal();
}

function removeCartItem(event) {
  var buttonClicked = event.target
  buttonClicked.parentElement.parentElement.remove()
  updateCartTotal()
}

// function addToCartClicked(event) {
//   var button = event.target
//   var shopItem = button.parentElement.parentElement
//   var title = shopItem.getElementsByClassName('title')[0].innerText
//   var price = shopItem.getElementsByClassName('price')[0].innerText
//   var imageSrc = '' //shopItem.getElementsByClassName('shop-item-image')[0].src
//   addItemToCart(title, price, imageSrc)
//   updateCartTotal()
// }

function addItemToCart(title, price, imageSrc, itemSlug, itemId) {
  var cartRow = document.createElement('div')
  cartRow.classList.add('line-item')
  var cartItems = document.getElementsByClassName('cart-details')[0]
  var cartItemNames = cartItems.getElementsByClassName('cart-item-title')
  for (var i = 0; i < cartItemNames.length; i++) {
      if (cartItemNames[i].innerText.trim() == title) {
          $('#addressDetails').modal('show');
        return
      }
  }
  var cartRowContents = `
      <div class="cart-item-quantity">
        <button class="btn btn-sm btn-default btn-reduce">-</button>
        <input type="text" value="1" data-price="${price}"   data-target="cart.quantity" data-id="${itemId}" class="cart-quantity-input" />
        <button class="btn btn-sm btn-default btn-add">+</button>
      </div>
      <div class="cart-item-title">
        ${title}
      </div>
      <div class="cart-item-price bold currency">
        ${price}
      </div>
      `
  cartRow.innerHTML = cartRowContents
  cartItems.append(cartRow)
  updateCartIcon()
  // cartRow.getElementsByClassName('btn-danger')[0].addEventListener('click', removeCartItem)
  // cartRow.getElementsByClassName('cart-quantity-input')[0].addEventListener('change', quantityChanged)
}

function updateCartTotal() {
  var cartItemContainer = $('.cart-details')
  var cartRows = cartItemContainer.find('.line-item')
  var deliveryCharge = cartItemContainer
  var total = 0
  for (var i = 0; i < cartRows.length; i++) {
      var cartRow = cartRows[i]
      var priceElement = cartRow.getElementsByClassName('cart-item-price')[0]
      var quantityElement = cartRow.getElementsByClassName('cart-quantity-input')[0]
      var price = parseFloat(priceElement.innerText.replace('QAR', ''))
      var quantity = quantityElement.value
      total = total + (price * quantity)
  }
  total = Math.round(total * 100) / 100
  document.getElementsByClassName('cart-total-price')[0].innerText = total
}
function updateCartIcon() {
  var itemLength = $('.line-item').length
  $('.cart-text').text(itemLength)
  if(itemLength < 1 ) {
      $('.cart-float').hide();
      $('#addressDetails').modal('hide');
  }
  else $('.cart-float').show();
}

function sortStore(){
    var loc = JSON.parse(localStorage.getItem("location"))
    $.ajax({
        url: '/api/v1/stores/by_location.json?latitude='+loc["latitude"]+'&longitude='+loc["longitude"],
        type: 'GET',
        success: function(data){
            $(".store-list-item").addClass("disabledbutton");
            if(data.store_id){
                var divs = $(".store-list");
                var firstDiv = divs.find("#store-"+data.store_id)
                firstDiv.removeClass("disabledbutton")
                $( firstDiv ).remove();
                $(firstDiv).prependTo( $(".store-list") );
            }
        },error:function(data){
            console.log(data.errors)
        }
    })
}


window.State = {}